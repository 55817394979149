import React from "react";
import HoneyAsset from "../../../images/IMA.jpg";
import ProcessingSupport from "../../../images/beekeeping.jpg"
import { NavLink } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";


import TitleDescription from "../../TitleDescription/TitleDescription";
export default function MSMEServices() {
  const services = [
    // {
    //   title: "Community",
    //   image: Community,
    //   href: "/Community",
    // },

   
    // {
    //   title: "Agri-Entrepreneurs",
    //   image: ProcessingSupport,
    //   href: "/agri-entrepreneur",
    //   // customPropValue : "Grower",
    //   data: "GBR Processing's agri-entrepreneurs are the backbone of our network; they're the progressive farmers who meet our rigorous verification process, ensuring the quality and reliability of the produce in our eco-system."
    // },
    // {
    //   title: "Agri-Storage Entrepreneurs",
    //   image: storage,
    //   href: "/agri-storage-entrepreneurs",
    //   data:"Agri-storage entrepreneurs play a pivotal role by monetizing their storage capacity, offering valuable space to agri-entrepreneurs. Their strategic management of resources adds a crucial dimension to the success of our agricultural network.      "
    // },
    {
      title: " Processing entrepreneur",
      image: HoneyAsset,
      href: "/",
      // customPropValue: "farming",
      data:"At GBR Processing, our processing entrepreneur play a vital role in monetizing their processing capacity, contributing to the efficient utilization of resources in food processing production"

    },
    // {
    //   title: "Agri-Retailer",
    //   image: Retailer,
    //   href: "/agri-retailers",
    //   // customPropValue: "farming",
    //   data:"At the heart of our agricultural network, agri-retailers stand as the driving force. Beyond selling agri-inputs, they diligently oversee and manage our verified agri-entrepreneurs and assets, playing an indispensable role in the success of our eco-system.    "

    // },

    // {
    //   title: "Agri-Transporter",
    //   image: Transporter,
    //   href: "/agri-transporters",
    //   // customPropValue: "farming",
    //   data:"GBR Agri-transporters are the backbone of our agricultural network, ensuring efficient product movement and optimal resource utilization. With access to our dynamic marketplace, they can monetize their transportation capacity effectively, contributing to the sustainable growth of the agri-industry.   "

    // },
  ];

  return (
    <Container className="container for-farmer-section2 px-0  subSectionWrapper">
 


      <TitleDescription
        classname="mb-0 mt-0"
        classname2="mb-5"
          title="Who are an Agri-MSMEs"
          description="Agri-MSMEs ( Micro-entrepreneurs) are micro and small-sized enterprises operating within the agro sector, encompassing agri-food processing units. They play a vital role in the hyperlocal agro value chain, contributing to economic development, employment, and sustainable agriculture. Support for Agri-MSMEs is crucial for fostering inclusive growth and enhancing the resilience of the agro economy.
          "
        />

      <Row className="justify-content-center ">
        {services.map((e) => {
           const navLinkProps = {
            to: { pathname: e.href },
          };
  
          // Add customPropValue to NavLink props if it exists
          if (e.customPropValue) {
            navLinkProps.to.state = { customProp: e.customPropValue };
          }
          return (
            
            <Col md={5} className="mb-4">
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-decoration-none  container-heading1 mt-0 mb-4 text-decoration-none">
                  {e.title}
                </h3>

                <p className="mb-0 container-para
"> {e.data} </p>
                  <NavLink to={e.href}  className="text-white">
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-3  mb-4"
                    >
                     Explore
                    </Button>
                  </NavLink>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

