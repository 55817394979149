import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

const commonProps = {
  className1: "aboutBgGrey",
};

const ffSection = (
  <HomeSubSection
  buttoncont1="Request for Demo"
  demoTrue = "true"
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport2"
    descriptionTitle="Create & manage own farmer community by using CBO solution"
    description="GBR Processing empowers Agri-entrepreneurs to establish and nurture their unique farmer communities, fostering local trade and collaboration. Through GBR, agri-entrepreneurs can connect with fellow agri-producers, share valuable insights, and facilitate seamless transactions, ultimately revitalizing the local agricultural ecosystem while promoting sustainable growth."
  />
);

const ffSection1 = (
  <HomeSubSection
  buttoncont1="Request for Demo"
  demoTrue = "true"
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport2"
    descriptionTitle="Create & manage own farmer community by using CBO & ADN solution"
    description="GBR Processing empowers Agri-entrepreneurs to establish and nurture their unique farmer communities, fostering local trade and collaboration. Through GBR, agri-entrepreneurs can connect with fellow agri-producers, share valuable insights, and facilitate seamless transactions, ultimately revitalizing the local agricultural ecosystem while promoting sustainable growth."
  />
);

const growerSection = (
  <HomeSubSection
  buttoncont1="Request for Demo"
  demoTrue = "true"
    {...commonProps}
    className="rowReverseAbout--mod borderedCards borderedCards--mod"
    className3="processingSupport4"
    descriptionTitle="Contract Farming by using OFC account"
    description="GBR Processing offers a unique service that facilitates agri-entrepreneurs in engaging in contract farming through the convenience of their OFC (Organic farming company) accounts. This streamlined platform not only simplifies the process of managing contractual agreements but also provides a centralized hub for entrepreneurs to efficiently oversee and optimize their agricultural operations."
  />
);

const PMSection = (
  <>
  <HomeSubSection
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport3"
    description="GBR Processing facilitates processing entrepreneur in establishing their own brand by offering a Food Processor Account as part of their services. This comprehensive solution empowers managers to oversee the entire production cycle, from cultivation to processing, fostering brand autonomy and ensuring high-quality, market-ready agricultural products.
"
    descriptionTitle="
Food-Processor"
  />


  <HomeSubSection
  {...commonProps}
  className="rowReverseAbout--mod borderedCards borderedCards--mod"
  className3="processingSupport5"
  description="Advisory & Training is a business growth service offered by GBR Processing, specifically designed for food processing entrepreneurs. This service provides expert guidance and training to help businesses optimize their operations, enhance productivity, and achieve sustainable growth. By leveraging industry insights and best practices, GBR Processing empowers entrepreneurs to navigate challenges and capitalize on opportunities in the competitive food processing sector.
"
  descriptionTitle="
  Advisory & Training

  "
/>


<HomeSubSection
  {...commonProps}
  className="rowReverseAbout borderedCards borderedCards--mod"
  className3="price-discovery-service"
  description="Price Discovery is a service offered by GBR Processing to food processing entrepreneurs, providing them with crucial information about market prices for their products. This service enables businesses to make informed pricing decisions by offering insights into current market trends and pricing dynamics. By utilizing Price Discovery, entrepreneurs can strategically position their products in the market and maximize their profitability.
"
  descriptionTitle="
  Price Discovery

  "
/>

<HomeSubSection
  {...commonProps}
  className="rowReverseAbout--mod borderedCards borderedCards--mod"
  className3="processingSupport7"
  description="Marketplace is a service offered by GBR Processing that enables food processing entrepreneurs to sell their products. This platform provides businesses with the opportunity to reach a wider audience and increase their sales. By connecting sellers with potential buyers, GBR Processing's Marketplace helps entrepreneurs expand their market presence and grow their customer base.
"
  descriptionTitle="
  Marketplace 

  "
/>
</>
);



const LPSection = (
  <HomeSubSection
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="LPaccount"
//     description="Utilizing a Logistic Provider account as a business growth service, agri-transporters can leverage GBR Processing to enhance their operations. This account offers a specialized platform connecting logistics providers with agricultural stakeholders, fostering efficient communication and resource allocation. Agri-transporters can optimize their routes, collaborate with farmers, and expand their network through this service, contributing to increased business growth. GBR Processing's Logistic Provider account serves as a valuable tool for agri-transporters, enabling them to elevate their services and cultivate growth opportunities within the agricultural supply chain.
// "
escription="Agri-transporters can boost their business growth with GBR Processing's Logistic Provider account a specialized platform connecting them with agricultural stakeholders. This tool streamlines communication, optimizes routes, and facilitates collaboration with agri-business, enhancing resource allocation. By leveraging this service, agri-transporters can expand their networks and elevate their services within the global agricultural supply chain, fostering increased business growth.
"
    descriptionTitle="
Logistic provider by using Logistic provider account"
  />
);
export default function ProcessingSubsection(props) {

  return (
    <div className="subSectionWrapper" id="FieldSupport">
      <h2 className="container-title text-center">Business Growth Service</h2>
      {PMSection}
    </div>
  );
}
