import React from "react";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import StorageMachImg from "../../../images/gbrHome/storageMachine.webp";
import ProcessingMachImg from "../../../images/gbrHome/processingMachine.webp";
import FillingMachImg from "../../../images/gbrHome/fillilingMachine.webp";

export default function InvestableMachinery({section}) {
  return (
    <>
    {  <div className="pt-4" id="ProcessingMachinery">
        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image8"
          descriptionTitle="
            Processing Machinery"
          description="
          GBR Processing offers specialized loan services to assist processing entrepreneurs in purchasing essential processing machinery. This support enables entrepreneurs to invest in equipment that drives their business growth and efficiency.
                    "
          src={ProcessingMachImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          // href1="auth/3/signin"
          // buttoncont1="Sign In"
        />
      </div>}
      <div className="pt-4" id="StorageMachinery">
        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image9"
          description="GBR Processing provides targeted loan services to help processing entrepreneurs acquire storage machinery, enabling them to enhance their operational capabilities and efficiency. "
          descriptionTitle=" Storage Machinery"
          src={StorageMachImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          // href1="auth/3/signin"
          // buttoncont1="Sign In"
        />
      </div>



     { 
     <div className="pt-4" id="FillingMachinery">
        <HomeSubSection
          className3="image10"
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
            Filling & Packing Machinery "
          description="GBR Processing offers dedicated loan services to support processing entrepreneurs in acquiring Filling & Packing Machinery, optimizing their production and packaging processes.
                    "
          src={FillingMachImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          // href1="auth/3/signin"
          // buttoncont1="Sign In"
        />
      </div>}
    </>
  );
}
