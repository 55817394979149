import React from "react";
import StepCards from "../stepCards/StepCards";
import step1 from "../../images/ae-journey/ae1.png";
import step2 from "../../images/ae-journey/ae2.png";

import step3 from "../../images/step2.png";
import step4 from "../../images/ae-journey/ae3.png";
import step5 from "../../images/ae-journey/ae4.png";
import img5 from "../../images/ae-journey/ae5.png";
import img1 from "../../images/arrow.svg";
import { Row, Col } from "react-bootstrap";
import { Container } from "@material-ui/core";

export default function CMProcess() {
  const stepCardContent = [
    {
      title: "Discover GBR processing",
      bodycopy:
        "Discover GBR Processing either through offline or online channels (e,g. we are part of CMN.Network)",
    },

    {
      title: "Brochure Review",
      bodycopy:
        "GBR Processing provides a brochure of the products it manufactures.",
    },
    {
      title: "Product Selection",
      bodycopy: "The brand selects a product from the brochure.",
      frontarrow: "true",
    },
  ];

  const stepCardContentRow2 = [
    {
      title: "Sample Submission",
      bodycopy: " GBR Processing sends a product sample to the brand.",
      backArrow: "true",
    },

    {
      title: "Manufacturing Assignment",
      bodycopy:
        "GBR Processing assigns a processing unit  for the selected product. ",
      backArrow: "true",
    },

    {
      title: "Raise RFQ",
      bodycopy: "Brand will send RFQ for selected product.",
      backArrow: "true",
    },
  ];

  const stepCardContentRow3 = [
    {
      title: "Brand Approval",
      bodycopy:
        "GBR Processing receives approval from the brand for manufacturing.",
      backArrow: "true",
    },

    {
      title: "Purchase Order",
      bodycopy:
        "The brand issues a purchase order for the approved product.              ",
      backArrow: "true",
    },

    {
      title: "Manufacturing Start",
      bodycopy:
        "GBR Processing begins manufacturing based on the purchase order.              ",
      backArrow: "true",
    },
  ];

  const stepCardContentRow4 = [
    {
      title: "Transport Arrangement",
      bodycopy: " GBR Processing arranges transportation for the product.",
      backArrow: "true",
    },
    {
      title: "Quality Testing",
      bodycopy:
        "GBR Processing conducts quality testing on the manufactured product.",
      backArrow: "true",
    },

    {
      title: "Order Status Updates",
      bodycopy:
        "GBR Processing provides regular order status updates to the brand.              ",
      backArrow: "true",
    },
  ];

  const stepCardContentRow5 = [
    {
      title: "Final Delivery",
      bodycopy: " GBR Processing delivers the order to the brand.",
      backArrow: "true",
    },

    {
      title: "Remaining Payment",
      bodycopy: " Brand will issue the remaining payment to GBR Processing.",
      backArrow: "true",
    },
  ];
  return (
    <>
      <h2 className="text-center container-title mb-4">Our Process </h2>
      <Container>
        <div className="d-flex flex-wrap p-0">
          {stepCardContent.map((e, i) => {
            return (
              <>
                <div className="process-container d-flex align-items-center">
                  <StepCards
                    classname="process-container-card"
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                  {!e.backArrow &&
                    i < stepCardContent.length - 1 &&
                    !e.frontarrow && (
                      <div className="process-container-img-container">
                        <img src={img1} />
                      </div>
                    )}
                </div>
              </>
            );
          })}{" "}
        </div>

        <div>
          {" "}
          <Row>
            <Col md={4}></Col> <Col md={4}></Col>{" "}
            <Col md={4} className="d-flex flex-wrap justify-content-center">
              {" "}
              <div className="process-container-img-container ">
                <img className="vertical-arrow" src={img1} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-flex flex-wrap p-0">
          {stepCardContentRow2.map((e, i) => {
            return (
              <>
                <div className="process-container d-flex align-items-center">
                  <StepCards
                    classname="process-container-card"
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                  {i < stepCardContent.length - 1 && (
                    <div className="process-container-img-container process-container-img-container-rev">
                      <img src={img1} />
                    </div>
                  )}
                </div>
              </>
            );
          })}{" "}
        </div>

        <div>
          {" "}
          <Row>
            <Col md={4} className="d-flex flex-wrap justify-content-center">
              {" "}
              <div className="process-container-img-container ">
                <img className="vertical-arrow" src={img1} />
              </div>
            </Col>
            <Col md={4}></Col> <Col md={4}></Col>{" "}
          </Row>
        </div>

        <div className="d-flex flex-wrap p-0">
          {stepCardContentRow3.map((e, i) => {
            return (
              <>
                <div className="process-container d-flex align-items-center">
                  <StepCards
                    classname="process-container-card"
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                  {i < stepCardContent.length - 1 && (
                    <div className="process-container-img-container ">
                      <img src={img1} />
                    </div>
                  )}
                </div>
              </>
            );
          })}{" "}
        </div>

        <Row>
          <Col md={4}></Col> <Col md={4}></Col>{" "}
          <Col md={4} className="d-flex flex-wrap justify-content-center">
            {" "}
            <div className="process-container-img-container ">
              <img className="vertical-arrow" src={img1} />
            </div>
          </Col>
        </Row>

        <div className="d-flex flex-wrap p-0">
          {stepCardContentRow4.map((e, i) => {
            return (
              <>
                <div className="process-container d-flex align-items-center">
                  <StepCards
                    classname="process-container-card"
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                  {i < stepCardContent.length - 1 && (
                    <div className="process-container-img-container process-container-img-container-rev">
                      <img src={img1} />
                    </div>
                  )}
                </div>
              </>
            );
          })}{" "}
        </div>

        <div>
          {" "}
          <Row>
            <Col md={4} className="d-flex flex-wrap justify-content-center">
              {" "}
              <div className="process-container-img-container ">
                <img className="vertical-arrow" src={img1} />
              </div>
            </Col>
            <Col md={4}></Col> <Col md={4}></Col>{" "}
          </Row>
        </div>

        <div className="d-flex flex-wrap p-0">
          {stepCardContentRow5.map((e, i) => {
            return (
              <>
                <div className="process-container d-flex align-items-center">
                  <StepCards
                    classname="process-container-card"
                    src={e.img}
                    stepTitle={e.title}
                    stepContent={e.bodycopy}
                  />
                  {i < stepCardContentRow5.length - 1 && (
                    <div className="process-container-img-container">
                      <img src={img1} />
                    </div>
                  )}
                </div>
              </>
            );
          })}{" "}
        </div>
      </Container>
    </>
  );
}
