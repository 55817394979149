import React from "react";
import FarmerImg from "../../../images/gbrPN/PNImg1.webp";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import DTN from "../../../images/GBR Processing (DTN Network) - DTN Network - 26-08-2024 C.gif";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import HeaderComponent from "../Header/HeaderComponent";
import { Col, Container, Row } from "react-bootstrap";

import FooterComponent from "../Footer/FooterComponent";
import AgribusinessClub from "../../Club/AgribusinessClub";
import ProductionNetworkBenefits from "./ProductionNetworkBenefits";
import ProductionNetworkServices from "./ProductionNetworkServices";

export default function ProductionNetwork() {
  const AgriEntreprenuer = ["Grower"];
  const ContractManufracturer = ["Production-Manager"];
  const Storageentrepreneur = ["FF"];
  const Transporter = ["Driver"];
  return (
    <>
      <HeaderComponent />
      {/* <NetworkBanner
        bannerTitle="Network for Agrifood MSMEs to Monetize the unutilized capacities to maximize the profit"
        bodycopy="Job creation | Digital transformation | Brand differentiation | Investable asset management | Organic certification"
        className="pnbg"
      /> */}

      <UpdatesBanner
        className="pnbg text-white"
        bannerTitle="Monetize Platform"
        bannerbodycopy1={[
          // "Introduce new line of business to manage assets and ",
          // <br className="d-lg-block d-none"></br>,
          // "Create your own network of Rural-Entrepreneur",

          "Network for Processing Entrepreneurs to Monetize the unutilized capacities ",
          <br className="d-lg-block d-none"></br>,
          "to maximize the profit",
        ]}
        demo="true"
      />

      <div className="mt-5 mb-5">
        <Container>
          <h4 className="container-title text-center mx-md-4 ">
            Managed Plant Production Network{" "}
          </h4>
          <Row className="justify-content-center">
            <Col md={6} sm={12}>
              <img
                className=" w-100 h-100 cursor-initial"
                src={DTN}
                alt="Infographics"
              />
            </Col>
          </Row>
        </Container>{" "}
      </div>
      {/* <ProductionNetworkServices/> */}

      <ProductionNetworkBenefits />
      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">
          BENEFICIARIES OF THE PPN NETWORK
        </h2>

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="GBR Processing's agri-entrepreneurs are the backbone of our network; they're the progressive farmers who meet our rigorous verification process, ensuring the quality and reliability of the produce in our eco-system.
          "
          descriptionTitle="
              Agri-Entrepreneur"
          src={FarmerImg}

          onClick={() => {
            localStorage.setItem("userType", "Grower");
          }}
          href1="/auth/3/signin"
          buttoncont1="Sign In"

          href2= "/auth/3/signup"
          buttoncont2="Register"
        />
<div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'> Agri-Entrepreneur</h2>

<AgribusinessClub type = {AgriEntreprenuer}/>
    </div> */}

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image15"
          description="At GBR Processing, processing entrepreneur play a vital role in monetizing their processing capacity, contributing to the efficient utilization of resources in food processing production
              "
          descriptionTitle="
          Processing entrepreneur "
          src={ProductionManagerImg}
          href1="/auth/3/signin"
          buttoncont1="Sign In"
          href2="/auth/3/signup"
          buttoncont2="Register"
          onClick={() => {
            localStorage.setItem("userType", "Production-Manager");
          }}
        />

        <div className="subSectionWrapper">
          <h2 className="container-title mb-md-5 text-center">
            {" "}
            Processing entrepreneur
          </h2>

          <AgribusinessClub bodycopy="Processing entrepreneur not found" type={ContractManufracturer} />
        </div>

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="imageStrCommittee"
          descriptionTitle="
              AgriMiner"
          description="GBR Processing enables individual consultant to earn extra earnings anytime, from anywhere by just uploading warehouse and processing outlet details through the app. Once these details gets authenticated and approved by GBR Processing representative, you will get your commission in your application digital wallet and you can transfer from your digital wallet to bank account."
          onClick={() => {
            localStorage.setItem("userType", "AgriMiner");
          }}
          href2="/auth/3/signup"
          buttoncont2="Register"
          href="auth/3/signup"
          src={ProductionManagerImg}
          href1="/auth/3/signin"
          buttoncont1="Sign In"
        />

<div className="subSectionWrapper">
          <h2 className="container-title mb-md-5 text-center">
            {" "}
            AgriMiner
          </h2>

          <AgribusinessClub bodycopy="AgriMiner not found" type={["AgriMiner"]} />
        </div>

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="agri-storage-entreprenuer-role"
          description="Agri-storage entrepreneurs play a pivotal role by monetizing their storage capacity, offering valuable space to agri-entrepreneurs. Their strategic management of resources adds a crucial dimension to the success of our agricultural network.
              "
          descriptionTitle="
              Agri-Storage Entreprenuer"
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />
        <div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'> Agri-Storage Entreprenuer</h2>

<AgribusinessClub type = {Storageentrepreneur}/>
    </div> */}

        {/* <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="agri-transporter-role"
          description="GBR Agri-transporters are the backbone of our agricultural network, ensuring efficient product movement and optimal resource utilization. With access to our dynamic marketplace, they can monetize their transportation capacity effectively, contributing to the sustainable growth of the agri-industry.
              "
          descriptionTitle="
              Agri Transporter"
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />

<div className='subSectionWrapper'>
<h2 className='container-title mb-md-5 text-center'> Agri Transporter</h2>

<AgribusinessClub type = {Transporter}/>
    </div> */}
      </div>

      {/* <div className="subSectionWrapper main-wrapper2">
<h2 className="container-title text-center ">
          DTN NETWORK OPERATORS       </h2>
        
<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards"
          className3="agribusiness-img"
          description="A farm enterprise, also known as a farming enterprise, is an agribusiness that is primarily engaged in agricultural activities. This includes the cultivation of crops, the raising of livestock, and other related farming practices. We help farm enterprises to become Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.


              "
          descriptionTitle="
          Farm Enterprise

          "
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards"
          className3="agri-foodmanufacturer-img"
          description="Food Processing Enterprises are companies involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.
              "
          descriptionTitle="
          Food Processing Enterprises

          "
          src={ProductionManagerImg}
          href4="http://cbn.gbrapp.com/"
          buttoncont1="Sign In"
        />
      </div> */}
      <FooterComponent />
    </>
  );
}
