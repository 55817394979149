import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import AgentForm from '../AgentForm/AgentForm'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'

export default function Agent() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner
          className="AgentBanner"
          bannerText={[  "Become GBR Processing agent to help Processing entrepreneurs  ",
          <br className="d-lg-block d-none"></br>,
          " to monetize their unutilised capacities in your region",]}
          bannerbodycopy="To apply for a job as an GBR Processing agent, please fill in the form below "
          href="#AgentForm"
          buttonText5="Apply to become Agent"
          DlBtn ="true"
          dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&pli=1"
          DownloadText="Download App"
        />
        <div id="AgentForm">
        <AgentForm usertype="Agent"/>

        </div>
    <FooterComponent/>
    </>
  )
}
