import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AgriEnrepenurJourney from "../AgriEnrepenurJourney/AgriEnrepenurJourney";
import CTA from "../CTA/CTA";
import AgriEntreprenuerServices from "./AgriEntreprenuerServices";
import AgriEntreprenuerChallange from "./AgriEntreprenuerChallange";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgrientrepreneurBenefits from "./AgriEntrepreneurBenefits";
import AgrientrepreneurContribution from "./AgriEntrepreneurContribution";
import ProductionManager from "../pricingTabs/ProductionManager";
import CollectionProduct from "../CollectionProduct/CollectionProduct";
export default function ForAgriEntreprenuer() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="agriEntrepreneur-bg"
        bannerText={[
          "Want to become a processing entrepreneur   ",
          <br className="d-lg-block d-none"></br>,
          " to monetize unutilised manufacturing capacity ",
        ]}
        roleType="NULL"
        buttonText="Start Monetizing Now"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "PlantOwner");
        }}
        DlBtn="true"
        dlLink="/ComingSoon"
        DownloadText="Download App"
      />

      <div className=" subSectionWrapper mb-0">
        <Container>
          <TitleDescription
            titleClass="text-start"
            classname2="text-start"
            classname="mb-0 mt-0 "
            title="Discovering Processing entrepreneur"
            description=" Processing entrepreneur are micro and small companies that specialize in providing manufacturing services for the following products."
          />
          <CollectionProduct />
          {/* <AgriBusinessCard /> */}
        </Container>
      </div>
      <div className="main-wrapper2">
        <AgriEntreprenuerChallange />
      </div>
      <SolutionEcosystem
        type="CM"
        bodycopy="Ecosystem to monetize the unutilized manufacturing capacity to address the challenges."
      />
      <div className="main-wrapper2">
        <Container>
          <AgriEntreprenuerServices />
        </Container>
      </div>
      {/* <div className="">
        <AgriEntreprenurCertifications />
      </div> */}
      <div className="">
        <AgrientrepreneurBenefits />
      </div>

      <div className="main-wrapper2 subSectionWrapper">
        <Container>
          <AgriEnrepenurJourney />
        </Container>
      </div>
      <div className="">
        <Container>
          <AgrientrepreneurContribution />
        </Container>
      </div>
      {/* <div className="main-wrapper2">
        <Container className="subSectionWrapper">
          <h2 className="text-center title mb-4 mb-lg-5 container-title">
            Pricing
          </h2>
          <ProductionManager />
        </Container>
      </div> */}
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "Production-Manager");
            }}
            href="/auth/3/signup"
            heading="Join GBR Processing and monetize your unused manufacturing capacity today!"
            btnText="Register Now"
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
