import React from "react";
import "react-awesome-slider/dist/styles.css";

import HomeBanner from "../HomeBanner/HomeBanner";
// import ChallengesSection from "./ChallengesSection/ChallengesSection";
// import SolutionEcosystem from "./SolutionEcosystem/SolutionEcosystem";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";
import MSMEServices from "../MSMEServices/MSMEServices";
import MSMEChallenges from "../MSMEChallenges/MSMEChallenges";
import MSMEBenefits from "../MSMEBenefits/MSMEBenefits";
import MSMEContribution from "../MSMEContribution/MSMEContribution";
import { Container } from "@material-ui/core";
import CTA from "../../CTA/CTA";
import HomeServices from "../HomeServices/HomeServices";
import MSMECoustomerBenefit from "../MSMECoustomerBenefit/MSMECoustomerBenefit";
import OurServices from "../../OurServices/OurServices";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import GBRFarmingServices from "../../GBRFarmingServices/GBRFarmingServices";
// import OurServices from "../OurServices/OurServices";

export default function LandingPage() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="container-banner text-white"
        bannerText={[
          "We transform Agri-MSMEs founders to digital entrepreneurs so that ",
          <br className="d-md-block d-none"></br>,
          "they can monetize unutilized capacities to maximize the profit",
        ]}
        bannerbodycopy="Monetize unutilized capacity | Improve Top line | Access to Investable Assets

      "
        buttonText="Become a Digital Entrepreneur "
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "Production-Manager");
        }}
        roleType="NULL"
        DlBtn="true"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        DownloadText="Download App"
      />

      <div className="">
        <Container>
          <MSMEServices />
        </Container>{" "}
      </div>
      <div className="main-wrapper2">
        <MSMEChallenges />
      </div>
      <SolutionEcosystem
        type="MSME"
        bodycopy=" We provide  Digital Transformation Network as a monetizing platform for micro-entrepreneurs to monetize the un-utilized capacities and at the same time serve the Brands and Agri-Investors."
      />
      {/* <OurServices/> */}
      <GBRFarmingServices />

      {/* <Container>
        {" "}
        <HomeServices />
      </Container> */}
      {/* <div className="main-wrapper2">
        <MSMEBenefits />
      </div> */}

      {/* <Container>
        <MSMEContribution />
      </Container> */}

      <div className=" subSectionWrapper">
        <Container>
          <CTA
            onClick={() => {
              localStorage.setItem("userType", "Production-Manager");
            }}
            href="/auth/3/signup"
            heading=" Join the digital transformation now and unlock unprecedented growth and profitability!"
            btnText="Register Now"
          />
        </Container>
      </div>

      <FooterComponent />
    </>
  );
}
