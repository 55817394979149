import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import IAATab from '../WebSite/IAATab/IAATab';
import "./FinancialService.css";




export default function FinancialService(props) {
  const customPropValue = props.location?.state?.customProp ;

  const commonProps = {
    className1: "aboutBgGrey",
  };
  
  const ffSection = (

    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="    GBR Processing extends a diverse range of financial services to agri-storage providers, empowering them to optimize operations and achieve financial sustainability in the agricultural ecosystem.
  "
  
  />





  );
  
  const ffSection1 = (
   
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR Processing facilitates loan services tailored for agri-retailers, providing essential financial support to enhance their operations and contribute to the success of the agricultural supply chain.

  "
  
  />
  );
  
  const growerSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="GBR Processing facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, GBR Processing is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."
  
  />
  );
  
  const PMSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="GBR Processing offers loan facilitation service to processing entrepreneur to acquire machinery and operational technology in the food processing industry.
  "
  
  />
  );
  







  
  const LPSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR Processing enables agri-transporters to access tailored loan services, providing essential financial support for optimizing their transportation operations within the agricultural sector.

  "
  
  />
  );
  return (
    
    <>

    <HeaderComponent/>
    <UpdatesBanner
        className="financialSupportServiceBanner"
        bannerText={["Financial Services"]}
      />
      <div className="subSectionWrapper" >
      {/* <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService5"
        descriptionTitle="
      Loan"
      description="GBR Processing facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, GBR Processing is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."

      /> */}



{customPropValue === "farming" && growerSection }
      {customPropValue === "storage" ? ffSection : null}
       {PMSection}
      {customPropValue === "selling" ? ffSection1 : null}
      {customPropValue === "transport" ? LPSection : null}

      </div>

      {  <div id="MoneyMakingAssets" className="subSectionWrapper pb-0">
          <IAATab customProp={customPropValue} />
        </div>}
    <FooterComponent/>
    </>
  )
}
