import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./AboutusComponent.css";

export default function AboutBanner() {
  return (
    <div className="about-us-banner">
      <Container>
        <Grid container spacing={2}>
          <Grid item md={9} sm={12} xs={12} className="p-0">
            <h2>
            We enable processing entrepreneur to monetize unutilised manufacturing capacity</h2>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
